.grecaptcha-badge {

    @include media-breakpoint-down(sm) { display: none; }

}






@media screen and (max-width: 500px) {
    .relcarousel {
        display: none !important
    }

    div.main {
        font-size: 24px;
    }

    body {
        overflow: hidden;
        overflow-y: auto;
    }



}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
    .container.outer {
        max-width: 1400px;

        &.productdetails {
            max-width: 1140px;
        }
    }
}

.mobilesearch {
    i {
        font-size: 150% !important;
    }

    .form-control { border-radius: 5px 0px 0px 5px;}

    .input-group-text {
        border-radius: 0px 5px 5px 0px;

    }


}




@media (max-width: 767.98px) {
    .offcanvas-collapse {
        position: fixed;
        z-index: 2;
        top: 0px;
        /* Height of navbar */
        bottom: 0;
        width: 100%;
        padding-right: 1rem;
        padding-left: 1rem;
        overflow-y: auto;
        background-color: var(--gray-dark);
        transition: -webkit-transform .3s ease-in-out;
        transition: transform .3s ease-in-out;
        transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out;
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }

    .offcanvas-collapse.open {
        -webkit-transform: translateX(0rem);
        transform: translateX(0rem);
        /* Account for horizontal padding on navbar */
    }
}

.mobilemenu {

    -webkit-overflow-scrolling: touch;
    background: $reidblue;
    margin-top: 0px;
    @include radius(0px);
    z-index: 1050;
    border: none;
    overflow-y: auto;
    padding: 0px;
    padding-top: 0px !important;
    width: 100% !important;
    position: fixed !important;
    height: 100% !important;
    overflow-y: scroll !important;
    z-index: 1000 !important;

    /*left:0px !important; */

    ul.menu>li>a {
        display: block;
    }

    ul.menu {
        position: absolute;
        top: 150px;
        padding: 0px;
        margin: 0px;
        float: left;
        width: 100%;

        span.separator {
            display: none
        }

        li {
            border-bottom: darken($offblack, 5%) 1px solid;
            padding: 5px 0px 0px !important;
            padding-left: 0px !important;
            list-style: none;

            transition: background-color 1s ease;

            a:after {
                content: "\F27B";
                font-family: 'bootstrap-icons';
                color: #fff;
                padding-right: 15px;
                font-size: 80%;
                padding-top: 3px;
                vertical-align: top;
                float: right;

                transition: transform 0.5s ease;
                transform-origin: 0.5em 55%;
            }


            &.show {
                background-color: $reidblue;

                >a {
                    color: #fff !important;
                    text-decoration: none;

                    &:after {
                        color: #fff;
                        transform: rotate(90deg);
                    }
                }
            }


            span {
                color: $reidblue2  !important;
                font-size: 150%;
            }

            a {
                color: #fff;
                font-size: 160%;
                margin-left: 10px !important;
                font-weight: 500;
            }


            &#Cups {
                li a:after {
                    content: "";
                    font-family: 'bootstrap-icons';
                }
            }


            li {
                border: none;
                padding: 0px 10px !important;

                a {
                    margin-left: 10px !important;
                    color: $white ;
                    font-size: 100%;
                    font-weight: 500;

                    span {
                        color: $reidblue;
                    }

                    &:before {
                        color: #fff;
                        display: none
                    }

                }

            }


            ul {
                background-color: lighten($offblack, 5%);
                padding: 10px 0px;
                border-bottom: lighten($offblack, 10%) 1px solid;
                margin: 0px;
                display: none;

                &.show {
                    display: block;
                }


                ul {
                    display: block !important;
                    background-color: transparent !important;
                    margin-bottom: 10px;
                    padding-top: 0px;

                    li {
                        a {
                            color: #fff;
                            font-size: 18px;
                            font-weight: 500;

                            span {
                                color: #fff;
                            }

                            &:before {
                                color: #fff;
                                display: block
                            }
                        }


                    }


                }


            }




        }

    }

    li.item2 {
        float: left;
        position: absolute;
        left: -999999px
    }


    ul.menu.top a:before {
        content: "";
        padding-right: 2px
    }







}

.mobilecups li a.cupslink {
    font-size: 50% !important;
}
