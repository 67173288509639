// //.megacontent { z-index: 3}
// #SureFast .megacontent {
//     left: 0px !important;
// }
.megamenu {
    z-index: 2;

    li {
        a {
            font-size: 1rem;
            color: $white;
            padding: 10px;

            &:hover {
                color: $white !important;
                text-decoration: none;
                background-color: rgba($white, 0.1);
            }

            &.active {
                color: lighten($reidblue, 10%);
            }
        }
&:hover > a, &.current a {
    color: $white !important;
    background-color: rgba($white, 0.1) !important;
}


        // &.toplevel.current a {
        //     background-color: rgba($white, 0.1);
        // }

        .megacontent {
            left: -999em;
            transition: all 0s ease;
            z-index: 3;
            background-color: $white ;
            position: absolute !important;
            top: 40px;

            .colsep {
                margin: 5px 0px !important;
                float: left;
                width: 100%;
            }

            ul {
                padding: 0px;
                list-style: none;
            }

            li {
                span.nav-header a,
                span.nav-header {
                    font-size: 90% !important;
                    color: $reidblue !important;
                    font-weight: 700;
                    text-transform: uppercase;
                    border-bottom: 1px darken($cream, 7%) solid;
                    width: 100%;
                    display: block;
                }
            }

            a {
                font-size: 90%;
                color: darken($reidblue2, 5%) !important;
                padding: 2px 0px !important;

                .menusuffix {
                    font-weight: 700 !important;
                }

                &:hover {
                    text-decoration: underline;
                }
            }

            .btn-brands {
                font-size: 200% !important;
                color: $brown;
                border: 2px $reidblue solid;
                padding: 10px !important;
                display: inline !important;
            }

            .megafooter {
                background-color: $white;
                color: $reidblue;
                font-weight: 100;
                font-size: 150%;

                small {
                    color: rgba(#fff, 0.6);
                    font-size: 70%;
                    font-style: italic;
                }

                strong {
                    font-weight: 700;
                }
            }
        }
    }

    li:hover {
        .megacontent {
            left: 0;
            transition-delay: 0.05s;
        }


    }
}

//#Coffee .megacontent{ background-image: url("/images/menubg/megabg-coffee.jpg")}


//  #Strapping .megacontent  { left: 0px !important}

li.col {
    ul {
        margin-bottom: 10px;
    }
}

//#Sale .megacontent{ left: 0px  !important;}
#Cups .megacontent {
    li {
        a {
            font-size: 70% !important;

            img {
                width: 80px !important;
            }
        }
    }
}
