.breadcrumbwrap{
    background-color: rgba($lightblue,0.6);

nav.breadcrumb {
    padding: 5px 0px 3px;

    ol.breadcrumb {
        font-size: 14px;
        background-color: transparent !important;
        padding: 0px;
        margin: 0px
    }

}

.breadcrumb {

    background-color: transparent !important; //fadeout($cream,10%);
    border-radius: 1rem;
    padding: 0px;
    margin: 0px;
    font-size: 0.8rem;

    svg.separator {

        fill: darken($cream, 20%) !important;
    }

    a.pathway { color: rgba($reidtext,0.6);}
}

.breadcrumb-item+.breadcrumb-item::before {
    font-family: 'bootstrap-icons';
    content: "\F27B";

}


.breadcrumb-item.active {
    color: $reidblue;
    font-weight: 500;
}
}