header.topmenu {
    font-size: 14px;
    background-color: darken($reidblue, 30%);

    a {
        color: darken($white, 30%);
        margin-right: 15px;

        &.signin-out-btn {
            color: $white;
            font-weight: 500;
        }
    }

}






a.btn.jmodedit {
    display: none;
}

header {
    background-color: $white;
    color: $white;
    transition: all 0.5s ease;
    .logobar {
      transition: all 0.5s ease;
    }

    &.shrunken {
        padding: 0%;
        background-color: rgba($reidblue2, 0.9);

        input.productSearch { padding-top: 0px; padding-bottom: 0px;}
        .logobar {
          padding-top: 5px !important;
          padding-bottom: 5px !important;
        }
        .searchbutton {
          font-size: 1em !important;
        }
          nav.subnav {

    opacity: 0;
    font-size: 0em;
    padding: 0px  !important;
    .container { height: 0px; padding: 0px !important;}
      }
      }


    a {
        color: $reidblue;
    }

    /*background-image:url("coffeeheader.jpg");*/
    background-repeat: no-repeat;
    background-size: cover;

    .logobar.container {
        max-width: 1400px;



    }

    .popover-btn {
        color: $reidblue  !important
    }

    //font-family: 'bootstrap-icons';

    .btn-outline-secondary {
        color: $white  !important;
        background-color: $reidblue;
        border-color: $reidblue;

        &:hover {
            background-color: $green;
            border-color: $green
        }
    }

    .form-control .cf-searchmod-wrapper {
        border: 0px
    }

    input.productSearch { }

    .loginbox {
        position: relative;
    }

    .loginfloatbox {
        position: absolute;
        background-color: $white;
        bottom: 0px
    }

    .btn-popover-container {
        display: inline-block;

        &:hover {
            cursor: pointer;
            text-decoration: underline !important;
            color: $reidblue
        }
    }


    .btn-popover-container .btn-popover-title,
    .btn-popover-container .btn-popover-content {
        display: none;
    }


    #login-form {
        label {
            justify-content: flex-end
        }
    }

    .searchouterwrap {
        background-color: $cream;

        #searchresults {
            background-color: #fff;
        }

        @media screen and (max-width: 830px) {
            top: 120px;
        }
    }

}