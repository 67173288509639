.productdetails-view,
.category-view {
  h1 {
    font-size: 1.8rem;
  }
  #desc,
  #reviews {
    display: block;
    position: relative;
    top: -150px;
    visibility: hidden;
  }

  ul.datasheets {
    padding: 0;
    li {
      list-style: none;
      &::before {
        font-size: 2rem;
        content: ("\F756");
        margin-right: 0.2rem;
        position: relative;
        top: 0.1rem;
        font-family: "bootstrap-icons";
      }
    }
  }

  .fractions {
    font-size: 1rem;
  }
  .metaltype,
  .engrave {
    position: absolute;
    right: 40px;
    top: 20px;
    z-index: 2;
  }

  .product-saving {
    color: darken($green, 10%);
  }

  /*** custom checkboxes ***/

  input[type="checkbox"] {
    display: none;
  }

  /* to hide the checkbox itself */
  input[type="checkbox"] + label:before {
    //font-family: FontAwesome;
    font-family: "bootstrap-icons";
    display: inline-block;
  }

  label strong.price {
    color: $reidblue;
  }

  input[type="checkbox"] + label:before {
    content: "\f0c8";
    color: lighten($offblack, 20%);
  }

  /* unchecked icon */
  input[type="checkbox"] + label:before {
    letter-spacing: 10px;
  }

  /* space between checkbox and label */

  input[type="checkbox"]:checked + label:before {
    content: "\f14a";
    color: $green;
  }

  /* checked icon */
  input[type="checkbox"]:checked + label:before {
    letter-spacing: 10px;
  }

  /* allow space for check mark */

  .full-width {
    background-color: #ffffff;
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;

    h2 {
      font-size: 200 rem;
      font-weight: 100;
      color: rgba($offblack, 0.7);
    }

    h3 {
      font-size: 80%;
      font-weight: 600;
    }
  }

  p:empty {
    display: none;
  }

  .border-dark {
    border-color: rgba($offblack, 0.3) !important;
  }

  .text-muted {
    color: rgba($offblack, 0.8) !important;

    @media screen and (max-width: 830px) {
      font-size: 60%;
    }
  }

  .title {
    background-color: $offblack;

    h2 {
      color: $white !important;
      font-weight: 100;
    }
  }

  .specs {
    background-color: #64594f;
    color: rgba($white, 0.9) !important;

    h2 {
      font-weight: 100;
      color: $white;

      span {
        color: $reidblue;
      }
    }
  }

  .youtube {
    background-color: $reidblue;
    color: rgba($white, 0.9) !important;

    h4 {
      color: $reidblue;
    }
  }

  .reviews {
    background-color: $cream;
    background-image: url("/images/fullwidthbg/reviews-bg.jpg");
    color: $offblack;

    h2 {
      font-weight: 600;
    }
  }

  .reviewsfull {
    background-color: $white;
    color: $offblack;
  }

  .relcarousel {
    background-color: $cream;
    color: $offblack;
  }

  .cups {
    background-color: rgba($offblack, 0.1);
    color: $offblack;
    text-transform: uppercase;
    font-weight: 100;

    h2 {
      color: rgba($offblack, 0.7) !important;
    }

    ul {
      list-style: none;

      .col {
        z-index: 1;
      }

      @media screen and (max-width: 830px) {
        padding: 0px;
      }
    }

    &:before {
      content: "";
      float: left;
      position: absolute;
      left: 0px;
      bottom: 0px;
      width: 100%;
      height: 43%;
      z-index: -1;
      background-color: rgba($offblack, 0.1);

      @media screen and (max-width: 830px) {
        display: none;
      }
    }
  }

  .description {
    @include gradient(lighten($cream, 5%));
    color: $offblack;
    .spec_table {
      table {
        @extend .table, .table-striped, .table-hover, .table-sm;
      }
    }
  }
  .product_s_desc {
    ul {
      margin-top: 10px;
      list-style: none;
      padding-left: 1rem;
      li {
        font-weight: 500;
        font-size: 0.9rem;
      }
      li::before {
        content: ("\F285");
        margin-right: 0.2rem;
        position: relative;
        top: 0.1rem;
        font-family: "bootstrap-icons";
        color: $reidblue2;
      }
    }
    ul.ticks {
      li::before {
        content: ("\F633");
        margin-right: 0.2rem;
        position: relative;
        top: 0.1rem;
        font-family: "bootstrap-icons";
        color: $success;
      }
    }
  }

  .topdescwrapper {
    ul {
      display: none !important;
    }

    font-size: 80%;

    p {
      margin-bottom: 2px;
    }
  }

  .table-sm {
    font-size: 80%;
  }

  .card {
    .card-header {
      font-size: 80%;
      background-color: transparent;
    }
  }

  .input-group-text {
    background-color: $cream;
  }

  .countdownwrap {
    @include panel;

    #countdown {
      @include text-md;
    }

    .smalltext {
      @include text-sm;
    }

    .icon i {
      font-size: 42px;
    }

    #gmctime-countdown1 {
      color: $reidblue;
    }
  }

  .smalldesc {
    font-size: 80%;
    color: darken($cream, 50%);
    padding: 0px;
    margin: 0px;
  }

  .multibuytable {
    font-size: 70%;

    td,
    th {
      text-align: center;
      padding: 2px;
      border: 0px;
    }

    thead th {
      background-color: $cream;
    }
  }

  .table-bordered {
    border-top-right-radius: 15px !important;
    border-top-left-radius: 15px !important;
  }

  .childprodwrap {
    .item {
      .card {
        border: $cream 2px solid;
      }

      .name {
        font-size: 90%;
      }

      .product_price {
        color: $reidblue;
      }
    }
  }

  .carousel-inner {
    background-color: $cream;
  }

  .product-description-wrap {
    li {
      font-weight: 300;
    }
  }

  .compare {
    border: 1px;

    table.comparetable {
      border: none;

      td,
      th {
      }
    }

    table.comparetable thead {
      th {
        border-top: none;
      }
    }

    table.comparetable thead th + th,
    table.comparetable tr td {
      background-color: $cream !important;
    }

    table.comparetable thead th + th + th,
    table.comparetable tr td + td {
      background-color: transparent !important;
    }

    table.comparetable thead th + th {
      border-top: border $reidblue solid;
      border-left: border $reidblue solid;
      border-right: border $reidblue solid;
    }

    table.comparetable tr td {
      border-left: border $reidblue solid;
      border-right: border $reidblue solid;
    }

    table.comparetable tr:last-child td {
      border-bottom: border $reidblue solid;
      padding-bottom: 5px;
    }

    table.comparetable tr:last-child td + td {
      border-bottom: none;
    }

    table.comparetable tr td + td {
      border-left: none;
      border-right: none;
    }

    table.comparetable thead th + th + th {
      border-left: none;
      border-right: none;
      border-top: none;
    }

    .table-condensed {
      td {
        padding: 8px 8px 0px 8px !important;
      }
    }
  }

  .description {
    h2 {
      color: $reidblue2;
    }
    .desccolumns {
      // columns: 2;
    }

    h3 {
      color: $reidblue;
      font-size: 1.2rem;
    }

    p {
      margin-bottom: 0.8rem;
      font-size: 0.9rem;
      a {
        font-size: 1rem;
        text-decoration: underline !important;
        font-weight: 700 !important;
      }
    }

    table {
      font-size: 90% !important;

      td {
        font-size: 90% !important;

        h5 {
          font-size: 100% !important;
          font-weight: 700;
        }
      }
    }

    .whyshopcol {
      font-size: 80%;

      ul {
        margin-bottom: 10px;
        padding: 0px;
        list-style: none;

        li {
          font-weight: 600;
          margin-left: 20px;
        }
      }

      h2 {
        color: $reidblue !important;
        font-size: 120%;
      }
    }

    i,
    svg {
      color: $reidblue;
    }
  }

  button.btn-addtocart.btn-lg {
    font-size: 150%;
    padding: 3px 0px;
    width: 100%;
    display: block;
  }

  .PricepriceWithoutTaxTt,
  .PricesalesPriceTt.NoVAT {
    font-size: 250%;
    line-height: 80% !important;

    @media screen and (max-width: 500px) {
      font-weight: 500;
      font-size: 420%;
      text-align: center !important;
    }
  }

  .pricesmall {
    font-size: 16px;
  }

  .additional-images {
    top: 0;
    left: 0;
  }

  .multibuy {
    .saving {
      color: $green;
      font-weight: 500;
    }
  }

  .specs {
    .ribbon {
      position: absolute;
      left: -5px;
      top: -5px;
      z-index: 1;
      overflow: hidden;
      width: 120px;
      height: 120px;
      text-align: right;
    }

    .ribbon span {
      font-size: 120%;
      font-weight: bold;
      color: #fff;
      text-transform: uppercase;
      text-align: center;
      line-height: 30px;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      width: 150px;
      display: block;
      background: #79a70a;
      background: linear-gradient(#9bc90d 0%, #79a70a 100%);
      box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
      position: absolute;
      top: 28px;
      left: -32px;
    }

    .ribbon span::before {
      content: "";
      position: absolute;
      left: 0px;
      top: 100%;
      z-index: -1;
      border-left: 3px solid #555555;
      border-right: 3px solid transparent;
      border-bottom: 3px solid transparent;
      border-top: 3px solid #555555;
    }

    .ribbon span::after {
      content: "";
      position: absolute;
      right: 0px;
      top: 100%;
      z-index: -1;
      border-left: 3px solid transparent;
      border-right: 3px solid #555555;
      border-bottom: 3px solid transparent;
      border-top: 3px solid #555555;
    }
  }

  .specsouterwrap {
    column-count: 3;
    column-gap: 0;
    font-size: 80%;

    .specwrap {
      break-inside: avoid-column;
      border-color: rgba($cream, 0.2) !important;

      .spectitle {
        font-weight: 600;
        color: $reidblue;
        font-size: 110%;
      }

      .spectext {
      }
    }
  }

  .nav-tabs .nav-link {
    &.active {
      color: $reidblue;
    }
  }

  .addtocartbar {
    .quantity-container {
      background-color: $cream;
    }

    .qtyinput {
      border: $success 2px solid;
      //   background-color: transparent;
      //   text-align: center !important;
      //   font-size: 120%;
      //   color: #6c757d;
    }

    .btn-outline-secondary {
      border-color: #ced4da !important;
    }
  }

  .addcheckbox {
    .pricesmall {
      font-size: 70%;
      line-height: 100% !important;
      display: inline-block;
    }
  }
}

// Nutritional Facts

.performance-facts {
  h3 {
    color: $offblack;
    font-size: 200%;
  }

  // transform: rotate(-2deg);
  font-size: 80%;
  @include radius();
  background-color: $white;
  @include gradient($cream);
  /*position: absolute; right: 20px; top: -30px;*/
  border: 1px solid black;

  /*width: 280px;*/
  padding: 0.5rem;
  z-index: 2;

  table {
    border-collapse: collapse;
  }

  header {
    color: $offblack;
    background-color: $white;
  }
}

.performance-facts__title {
  font-weight: bold;
  font-size: 2rem;
  margin: 0 0 0.25rem 0;
}

.performance-facts__header {
  border-bottom: 10px solid black;
  padding: 0 0 0.25rem 0;
  margin: 0 0 0.5rem 0;

  p {
    margin: 0;
  }
}

.performance-facts__table {
  width: 100%;

  thead tr {
    th,
    td {
      border: 0;
    }
  }

  th,
  td {
    font-weight: normal;
    text-align: left;
    padding: 0.25rem 0;
    border-top: 1px solid black;
    white-space: nowrap;
  }

  td {
    &:last-child {
      text-align: right;
    }
  }

  .blank-cell {
    width: 1rem;
    border-top: 0;
  }

  .thick-row {
    th,
    td {
      border-top-width: 5px;
    }
  }
}

.small-info {
  font-size: 0.7rem;
}

.performance-facts__table {
  color: $offblack !important;
  font-size: 80%;
}

.performance-facts__table--small {
  .performance-facts__table {
    color: $offblack;
  }

  border-bottom: 1px solid #999;
  margin: 0 0 0.5rem 0;

  thead {
    tr {
      border-bottom: 1px solid black;
    }
  }

  td {
    &:last-child {
      text-align: left;
    }
  }

  th,
  td {
    border: 0;
    padding: 0;
  }
}

.performance-facts__table--grid .performance-facts__table {
  margin: 0 0 0.5rem 0;

  td {
    &:last-child {
      text-align: left;

      &::before {
        content: "•";
        font-weight: bold;
        margin: 0 0.25rem 0 0;
      }
    }
  }
}
