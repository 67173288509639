$reidblue: #114c90;
$reidblue2: #29a8df;
$lightblue: #dee4ef;
$reidtext: #364a5b;
$reidgrey: #d2d1cf;
$brown: #603b0d;
$lightorange: lighten($reidblue, 20%);
$highlight: #51b37f;
$white: #fff;
$cream: #ffffff;
$gold: #dfcfa3;
$offblack: #303239;
$green: #46b05a;

$primary: $reidblue;
$secondary: $reidblue2;
$success: #56a01a;
$info: #13101c;
$warning: #945707;
$danger: #d62518;
$light: #f8f9fa;
$dark: #343a40;

$border-width:                2px;
$breadcrumb-item-padding-x: 2px !important;



$box-shadow-sm:               0 .2rem .2rem rgba($offblack, .2);

$theme-colors: (
  "primary":    $reidblue,
  "secondary":  $reidblue2,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark
) !default;

//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
// Note: Deprecated $screen-xs and $screen-phone as of v3.0.1
$screen-xs: 480px;
$screen-xs-min: $screen-xs;
$screen-phone: $screen-xs-min;

// Small screen / tablet
// Note: Deprecated $screen-sm and $screen-tablet as of v3.0.1
$screen-sm: 768px;
$screen-sm-min: $screen-sm;
$screen-tablet: $screen-sm-min;

// Medium screen / desktop
// Note: Deprecated $screen-md and $screen-desktop as of v3.0.1
$screen-md: 992px;
$screen-md-min: $screen-md;
$screen-desktop: $screen-md-min;

// Large screen / wide desktop
// Note: Deprecated $screen-lg and $screen-lg-desktop as of v3.0.1
$screen-lg: 1200px;
$screen-lg-min: $screen-lg;
$screen-lg-desktop: $screen-lg-min;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);
