//Homepage Stuff //

.introtext {


color: $reidtext;

    .lead{ font-size: 1.5rem; font-weight: 700; letter-spacing: -0.01rem; line-height: 100%; margin-bottom: 0.6rem;}

}

section.surefast{


    .surefastwrapper {
        background-color: #FFFFFF;
        .product.item{ background-color: #FFFFFF;}}


 }

section.whoarewe{
 background-color: #f0f2f5;
    .colwrap { column-count: 3;}
}

section.brandbar {
    background-color: rgba(darken($brown, 10%), 0.93);
}

section.trustpilot {
    background-color: rgba(darken($brown, 20%), 0.95);
    padding: 40px 40px 80px;

    h2 {
        font-weight: 100;
        margin-bottom: 20px;
        color: $reidblue
    }

    .orange {
        color: $reidblue
    }

    .tpheader {
        margin-top: 35px
    }

    h3 {
        color: $reidblue;
        margin: 0px
    }

    p {
        font-size: 14px
    }

    .reviewwrap {
        margin-bottom: 15px
    }

}

section.blog {
    /*background-image: url(../images/nov-2016/blog-bg.jpg); background-size: cover; background-repeat: no-repeat;*/
    padding: 40px 0px;

    h2 {
        font-size: 32px;
        font-weight: 100;
        color: $reidblue;
        margin-bottom: 30px;
    }

    .blogpostwrap {
        background-color: #FFFFFF;
        @include shadow(5px);
        margin-bottom: 15px;


    }





}

//Homepage Stuff //