nav.navbar {
    z-index: 10;
    background-color: $reidblue;

    @include media-breakpoint-down(sm) { background-color: $white; }

    .mobilenav {
        i {
            font-size: 180%
        }

        a {
            padding: 0px !important
        }
    }

    button.navbar-toggler {
        padding: 0px;
        border: none;

        &:focus {
            background-color: $reidblue
        }
    }

    .navbar-toggler-icon {
        color: $reidgrey;
        background-image: none !important;
    }





}


.subnav {
    background-color: $lightblue !important;
    color: $reidblue;
    font-size: 1.2rem;

    .bi{ color: $success;}
    small {color: fade-out($reidblue,0.2); font-size: 1rem; text-transform: uppercase;}
    a{ color: $reidblue !important; padding: 0px !important; font-size: 1.2rem !important; letter-spacing: -0.5 !important;}
    .pipe{color: darken($lightblue,10%) !important; font-weight: 100 !important;}

}