
.contact-us-wrap {
    color: $white;

    main.container {
        background-color: transparent;

    }

    h1 {

        color: lighten($white,10%);
        font-size: 3rem;
        text-transform: uppercase;
        letter-spacing: -0.1rem;
    }

    h2{
        font-size: 150%;
        font-weight: 600;
        color: $white;
    }

    p.lead {
        font-size: 150%;
        font-weight: 600;
        color: rgba($white,0.7);
    }

    a {
        color: $white
    }

    .formContainer {
        .form-control{ border: 0px;}
        .btn-primary { background-color: $reidblue2;}
        .form-group {
            label {
                display: none
            }
        }
    }
}

.map-responsive {
    overflow: hidden;
    padding-bottom: 20%;
    position: relative;
    height: 0;
}

.map-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}