
.catheader {
    position:relative;
    width: 100%;
    color: #fff;
    background-size: cover;
    background-repeat: no-repeat;


    a{color: #fff !important;}
h1{ position: relative; z-index: 1;   background-color: rgba(#071b30, 0.7); letter-spacing: -0.2rem; padding: 20px; font-size: 2.5rem; text-transform: uppercase; color: $reidblue2;
    strong { color: #ffffff; font-weight: 100;}
 &::before, &::after{ content: ' '; padding: 10px;}

 @include media-breakpoint-down(sm) { font-size: 1rem;letter-spacing: -0.01rem;}


}
.container { padding: 150px 0px 50px 0px;
    @include media-breakpoint-down(sm) { padding:50px 0px 10px 0px;}
}

&::before{
    content: "";
    z-index: -1;
    display: inline;
    float: left;
    width: 100%;
    height: 100%;
    position: absolute;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    // background-image:  url('/images/cat-bg-banding.jpg');
    filter: grayscale(0.6);}


}
.childcatwrapper {background-color: rgba($offblack,0.7);}
.category-view,
#searchresults {
  .childcatswrapper {
    .item {
      /*border: 1px $cream solid; .radius; background-color: fadeout($cream,30%);*/
      a {
        font-size: 80%;
        font-weight: 500;
        color: $brown;
        line-height: 20px;
        display: block;
      }
    }
  }

  .card {
    background-color: $cream;
  }

  h1 {
    text-align: center;
    color: $reidblue;
    font-size: 3rem;

    font-weight: 400;

    @media screen and (max-width: 830px) {
      font-weight: 500;
      font-size: 130%;
      text-align: center !important;
    }
  }

  h3 {
    font-weight: 100;
    color: $brown;
  }

  .category_description {


    font-weight: 400;
    line-height: normal;
  }

  // .readMoreText {color: lighten($offblack,15%); p{ margin: 0px;}}
  .filterbar {
    background-color: $cream;
    @include radius();
  }

  .metadesc,
  .readMoreText,
  .category_description {
    h2 {
      color: $reidblue2;
      text-transform: uppercase; letter-spacing: -0.1rem;
      font-size: 2rem;

      @include media-breakpoint-down(sm) { font-size: 1.4rem; text-align: center; }

    }

    p {
      color: $reidtext;
      margin-bottom: 3px;

      font-size: 0.8rem;


      @media screen and (max-width: 830px) {
        padding: 0px 0px;
      }
    }

    @media screen and (max-width: 830px) {
      font-size: 80%;
      line-height: normal;
    }
  }


  .browse-view {

    .vm-product-media-container a { display: flex; width: 100%; height: 150px;     justify-content: center!important; &::after {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 150px;
        background: rgb(143, 143, 143);
        opacity: .03;
        pointer-events: none;
        border: #131313 solid 1px !important ;
    }
    &:hover::after {background: rgb(0, 0, 0);}
    }
  }

  .browse-view,
  #vm2-search {
    .products-row:last-child {
      .border-bottom {
        border: none !important;
      }
    }

    .product {



      .highlight {
        background-color: lighten($highlight, 45%);
        border: lighten($highlight, 0%) 1px solid;
        @include radius();
        position: relative;

        img.browseProductImage {
          @include shadow();
          @include radius();
        }
      }

      .ribbon {
        position: absolute;
        left: -5px;
        top: -5px;
        z-index: 1;
        overflow: hidden;
        width: 120px;
        height: 120px;
        text-align: right;
      }

      .ribbon span {
        font-size: 12px;
        font-weight: bold;
        color: #fff;
        text-transform: uppercase;
        text-align: center;
        line-height: 30px;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        width: 150px;
        display: block;
        background: lighten($highlight, 0%);
        // background: linear-gradient(lighten($highlight, 40%) 0%, lighten($highlight, 10%) 100%);
        // box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
        position: absolute;
        top: 28px;
        left: -32px;
      }

      .ribbon span::before {
        content: "";
        position: absolute;
        left: 0px;
        top: 100%;
        z-index: -1;
        border-left: 3px solid #555555;
        border-right: 3px solid transparent;
        border-bottom: 3px solid transparent;
        border-top: 3px solid #555555;
      }

      .ribbon span::after {
        content: "";
        position: absolute;
        right: 0px;
        top: 100%;
        z-index: -1;
        border-left: 3px solid transparent;
        border-right: 3px solid #555555;
        border-bottom: 3px solid transparent;
        border-top: 3px solid #555555;
      }

      .product-container {
        position: relative;
      }

      .bottomrow {
      }

      .btn-success {
      }

      h2,
      h3 {
        font-size: 90%;
        text-align: left;
        color: $offblack;
        font-weight: 500;

        a {
          color: $reidblue2;

          @media screen and (max-width: 830px) {
            font-size: 80%;
          }
        }
      }

      .PricepriceWithoutTaxTt {
        color: $reidblue;
        font-size: 150%;
        font-weight: 500;
      }

      .PricesalesPriceTt {
      }

      .pricesmall {
        font-size: 80%;
        color: lighten($offblack, 30%) !important;
        margin-right: 3px !important;
      }
    }
  }
}

.catbottom-stripe, #productstripe.productstripe {
  background-color: $cream !important;

  .grid{

    .imgwrap { background-position: center center; background-size: cover; position: relative;

        .imglink{ color: #fff; background-color: #35353597; width: 100%; height: 100%; position: absolute; left: 0px; top: 0px ;

            -webkit-transition: background-color 200ms ease-in;
            -moz-transition: background-color 200ms ease-in;
            -o-transition: background-color 200ms ease-in;
            -ms-transition: background-color 200ms ease-in;
            transition: background-color 200ms ease-in;

            p.small{color: #fff !important;opacity: 0.6;
                -webkit-transition: opacity 400ms ease-in;
                -moz-transition: opacity 400ms ease-in;
                -o-transition: opacity 400ms ease-in;
                -ms-transition: opacity 400ms ease-in;
                transition: opacity 400ms ease-in; }
            &:hover  p.small{opacity: 1;}

            &:hover{background-color: #39200656}

            h2{color: $reidblue; font-size: 250%; margin-bottom: 10px;

                -webkit-transition: color 200ms ease-in;
                -moz-transition: color 200ms ease-in;
                -o-transition: color 200ms ease-in;
                -ms-transition: color 200ms ease-in;
                transition: color 200ms ease-in;  }
            &:hover h2{color: $white;}

        }



    }
  }

  h2 {
    color: $reidblue;
    font-size: 2.5rem;
    font-weight: 400;
    text-align: center;
    margin-bottom: 40px;
    @media screen and (max-width: 830px) {
      font-size: 9vw;
    }
  }

  p {
    text-align: center;
    color: $offblack;
    margin-bottom: 3px;
    font-size: 90%;
    padding: 0px 100px;
    @media screen and (max-width: 830px) {
      padding: 0px 0px;
    }
  }

  @media screen and (max-width: 830px) {
    line-height: normal;
    padding: 0px 0px;
  }

  .stripes {
    h3 {
      color: $reidblue;
      font-size: 3.5rem;

      font-weight: 400;
      text-align: center;
      margin-bottom: 10px;
    }
  }
}

.catlandingwrapper {
  section.jumbotron {
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.filters {
  h3 {
    margin-bottom: 0px;
  }

  .spec {
    margin-bottom: 10px;
  }

  button.w-100 {
    padding: 0px;
    margin: 0px;
    text-align: left;
    font-size: 0.8em;
    color: lighten($offblack, 20%);

    b {
      display: none !important;
    }

    &:before {
      font-family: "Font Awesome 5 Pro";
      display: inline-block;
      content: "\f0c8";
      color: $offblack;
      margin-right: 5px;
    }

    &.btn-default {
      &:before {
        font-family: "Font Awesome 5 Pro";
        display: inline-block;
        content: "\f14a";
        color: $reidblue;
        margin-right: 5px;
      }
    }
  }
}

.container.browseheading {
  padding-left: 0px;
  padding-top: 25px;
  padding-bottom: 25px;

  .smalldesc {
    color: #fff !important;
    @include textshadow();
    font-size: 100%;
    font-weight: 300 !important;

    a {
      color: #fff;
    }
  }

  h1 {
    color: $gold;
    font-weight: 100 !important;
    font-size: 54px;
    @include textshadow();
  }
}

.catlanding {
  h1 {
    color: #ffffff !important;
    font-weight: 100 !important;
    text-transform: uppercase !important;
  }
}
