
#vmCartModule {
    position: relative;

    .shopping-cart {
        transition: all 0s ease;
        top: -99999px;
        display: block !important;
    }
}

//#vmCartModule {position: relative;  .shopping-cart { transition: all 0s ease; top: 35px; display: block !important;  }}
#vmCartModule:hover {
    .shopping-cart {
        top: 40px;
        transition-delay: 0.2s;
    }

}

#vmCartModule {

    .textline {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;

    }

}

.minicartcol {
        &:before {
        content: "";
        padding-top: 300px;
        margin-top: -300px;
    }
    .card {
        border: none
    }

}
.minicartcol {

}

.shopping-cart {
    .shopping-cart-inner {}

    .customProductData {
        display: none
    }

    @include gradient($cream);
    font-size: 80%;

    a {
        color: $offblack
    }

    a.btn {
        color: $white
    }

    .price {
        font-weight: 600
    }

    .shopping-cart-total {
        strong {
            color: $green;
        }
    }

    //  display: block !important;
    margin: 0px 0;
    float: right;

    width: 470px;

    @media (max-width: $screen-xs-max) {
        width: 310px;
    }

    position: absolute;
    border-radius: 3px;

    right: 0px;
    top:38px;
    z-index: 15;
    @include shadow(3px);
    border: none;
    color: $offblack;



    .shopping-cart-header {

        border-top: 1px solid #E8E8E8;



        .shopping-cart-total {
            font-size: 24px
        }
    }

    .shopping-cart-items {

        padding-top: 20px;
        padding-left: 0px;

        li {
            margin-bottom: 0px;
            list-style: none;
            text-align: left;
        }

        img {
            float: left;
            margin-right: 12px;
        }

        .item-name {
            display: block;
            padding-top: 10px;
            font-size: 16px;
        }

        .item-price {
            color: $offblack;
            margin-right: 8px;
        }

        .item-quantity {
            color: $offblack;
        }
    }

}

.shopping-cart:after {
    bottom: 100%;
    left: 55%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: $reidblue;

    border-width: 8px;
    margin-left: -24px;
}