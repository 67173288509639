html {
}

body {
  font-family: avenir, "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans",
    "DejaVu Sans", Verdana, sans-serif;
  overflow-x: hidden;
  color: $reidtext;
}

.affix {
  top: 0;
}
p {
  margin-top: 0px;
  margin-bottom: 1rem;
  &.lead {
    color: $reidblue;
    font-size: 1.5rem;
    line-height: normal;
    margin-bottom: 0.5rem;
  }
}

a {
  color: $offblack;
  text-decoration: none;

  &:hover {
    color: $reidblue;
  }
}
a.nav-link {
  display: block !important;
}

#modaladdJS {
  .btn-close {
    padding: 0px;
  }
}

.parallax-background {
  background-position: 50% -150px;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;

  -moz-transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.grecaptcha-badge {
  width: 70px !important;
  overflow: hidden !important;
  transition: all 0.3s ease !important;
  left: 4px !important;
}
.grecaptcha-badge:hover {
  width: 256px !important;
}

.modal-header {
  background-color: $reidblue;

  h3 {
    color: $white;
  }

  .close {
    text-shadow: none;
  }
}

.vm-wrap {
  fieldset {
    border: $reidblue 2px solid;
    @include radius();

    legend {
      width: auto;
      padding-left: 10px;
      padding-right: 10px;
      margin-left: 20px;
    }
  }
}

.imagebgwrap {
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  color: $cream;
  font-size: 90%;

  h1 {
    font-size: 200%;
  }

  h2 {
    font-size: 180%;
    font-weight: 100;
  }

  h1,
  h2,
  a {
    color: $reidblue;
  }
}

.popover.login {
  max-width: 600px !important;
  @media screen and (max-width: 830px) {
    left: -160px !important;
  }

  h3 {
    font-size: 170%;
  }

  h3,
  a {
    color: $reidblue;
  }

  a.btn {
    color: $white;
  }
}

.popover-body {
  width: 300px;
}

.popover-header {
  background-color: $white;
  border: none !important;
}

.popover-body {
  font-size: 90%;
  @include gradient(lighten($cream, 5%));
  @include radius();
  @include shadow();

  li {
    list-style: none;
    padding: 0px;

    &:before {
      font-family: "bootstrap-icons";
      content: "\f054";
      color: $reidblue;
      margin-right: 10px;
    }

    a {
      color: $offblack !important;
    }
  }

  ul {
    list-style: none;
    padding: 0px;
  }
}

div.overlay {
  transition: all 0s ease;
}

div.overlay.active {
  transition-delay: 0.25s;
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: rgba($offblack, 0.8);
  width: 100%;
  display: block;
  height: 100%;
  z-index: 2;
}

.trending {
  li {
    list-style: none;
  }

  .vm-product-descr-container-1 {
    a {
      font-size: 90%;
    }
  }
}

/*Generic Price styling*/
.PricepriceWithoutTaxTt,
.PricesalesPriceTt.NoVAT {
  color: $reidblue;
}

.pricesmall,
.PricesalesPriceTt.pricesmall {
  color: lighten($offblack, 30%) !important;
}

/*Generic Price styling*/

.backgroundimgwrap {
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.main.container {
  background-color: $white;
  padding-top: 15px;
}
main.container.outer {
  padding-top: 15px;
}

div.main.container {
  &.cart,
  &.checkout {
    background-color: transparent !important;
  }
}

.searchouterwrap {
  @include shadow();
}

.searchinnerwrap {
  background-color: #fff;
}

#catsearchresults {
  a.category {
    color: $reidblue !important;
  }
}

#searchresults {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 600px;

  #vm2-search {
    ul {
      list-style: none;
      padding: 0px;
    }
  }

  .vm2_search_add_image {
    img {
      width: 75px !important;
      height: 75px !important;

      @media screen and (max-width: 830px) {
        width: 100px !important;
        height: 100px !important;
      }
    }
  }

  h2 {
    a {
      font-size: 0.9rem;

      @media screen and (max-width: 830px) {
        font-size: 100% !important;
      }
    }
  }
}

.fastbuy {
  .PricepriceWithoutTaxTt {
    color: $reidblue;
    font-size: 150%;
    font-weight: 500;

    @media screen and (max-width: 830px) {
      font-size: 100% !important;
    }
  }

  .pricesmall {
    @media screen and (max-width: 830px) {
      display: none;
    }
  }
}

// .input-group-append .btn,
// .input-group-prepend .btn {
//     z-index: 0 !important;
// }

.mainprodrow {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.mainprodrow > [class*="col-"] {
  display: flex;
  flex-direction: column;
}

ul.myaccountwrap {
  list-style: none;
  padding: 0px;
  margin: 0px;

  li {
    display: inline-table;

    a {
      font-size: 80%;
    }
  }
}

.slick-prev,
.slick-next {
  font-size: 100%;
  top: 44%;
}

.slick-prev:before,
.slick-next:before {
  font-size: 200%;
  color: $offblack;
}

.slick-prev:before {
  font-family: "bootstrap-icons";
  content: "\f053";
}

.slick-next:before {
  font-family: "bootstrap-icons";
  content: "\f054";
}

.prod-mod-wrap {
  .addtocartbar {
    display: none !important;
  }

  h3 {
    font-size: 90%;
  }

  font-size: 90%;

  input[type="checkbox"]:checked + label {
  }

  label:before {
    font-size: 110%;
  }

  .PricepriceWithoutTaxTt {
    font-size: 100% !important;
  }
}

/*Prices Styles*/

.product-related-products {
  img {
    max-height: 150px !important;
    width: auto !important;
  }
}

.product-related-products,
.youmayalsoneed,
.childprodwrap {
  text-align: center !important;

  h5 {
    @include text-sm;
    text-align: center;
  }

  .vm-product-media-container a {
    float: left;
    text-align: center !important;
  }

  small {
    font-size: 80% !important;
    line-height: 100% !important;
  }

  .qtywrap {
    display: none !important;
  }

  .flex-grow-1 {
    flex-grow: 0 !important;
  }

  button.btn-addtocart,
  .btn-danger {
    font-size: 70%;
    padding: 4px 6px;
    text-align: center;
  }

  .pricewrap {
    display: inline;
  }

  .PricepriceWithoutTaxTt,
  .PricesalesPriceTt.NoVAT {
    text-align: center;
    font-size: 120% !important;
    line-height: 100% !important;
  }

  .pricesmall {
    display: none;
    font-size: 70% !important;
    line-height: 100% !important;
    display: inline-block;

    &.zerovat,
    &.incvat {
      display: none;
    }
  }

  .PricevariantModification {
    display: none !important;
  }
}

.PricevariantModification {
  display: none !important;
}

/*Prices Styles*/

.countdownwrap {
  flex-wrap: nowrap;
  min-width: 260px;

  #countdown {
    font-size: 80%;
  }

  .time {
    flex-wrap: nowrap !important;
  }

  .smalltext {
    font-size: 70%;
  }

  .icon i {
    font-size: 120%;
  }

  #gmctime-countdown1 {
    color: #fff;
  }
  #countdown-mobile {
    font-size: 80%;
    strong {
      color: $reidblue !important;
    }
  }

  @media screen and (max-width: 830px) {
    a i {
      color: $reidblue !important;
      font-size: 120% !important;
    }
  }
}

.mobile-countdown {
  z-index: 1019;
  background-color: $offblack;
  color: $white;
}

.cf_wrapp_all {
  ul {
    padding: 0px;

    li {
      padding: 0px;
    }

    li.cfLiLevel0 {
      font-size: 18px;
    }

    li.cfLiLevel1 {
      font-size: 18px;
      padding: 0px;
      margin-left: 5px !important;

      a {
        color: lighten($offblack, 30%);
      }
    }

    li.cfLiLevel2 {
      font-size: 14px;
      padding: 0px;
      margin-left: 5px !important;
    }
  }

  a {
    color: $offblack;
  }
}

.minicarthdr {
  color: $white;

  p {
    padding: 0px;
    margin: 0px;
  }
}

.mini-cart {
  text-align: left !important;

  .description {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
  }

  .vm_cart_products {
    @include text-sm;
  }

  .shopping-cart-header {
    .row {
      background-color: lighten($offblack, 75%);
      margin-bottom: 5px;
    }
  }

  .shopping-cart-total {
    @include text-md;
    color: $reidblue;
    font-weight: 500;
  }

  #tt_total_basket_div_basket {
    font-size: 150%;
    font-weight: 500;
  }
}

.stickybasketwrap {
  @media screen and (max-width: 500px) {
    position: relative;
    margin-top: 0px !important;
  }
}

.stickybasket {
  @media screen and (max-width: 500px) {
    position: relative;
    padding-top: 0px !important;
  }
}

.smalldesc,
.product-description-wrap,
ul.ticked {
  ul {
    padding-left: 15px;
    padding-bottom: 10px;
  }

  li {
    list-style: none;
    padding: 0px;
    padding-left: 20px;
    text-indent: -27px;

    &:before {
      font-family: "bootstrap-icons";
      content: "\F633";
      color: $green;
      margin-right: 10px;
    }
  }
}

ul.ticked {
  font-size: 90%;
  padding: 0px;
}

#fancybox-wrap {
  #fancybox-title {
    width: 100%;
    margin: 0px !important;
    text-align: center;
    width: 100% !important;
  }

  @media screen and (max-width: 830px) {
    width: 100% !important;
    left: 0px !important;
  }

  #fancybox-content {
    @media screen and (max-width: 830px) {
      width: 100% !important;
      height: auto !important;
      padding: 5px !important;
    }
  }

  .popupheader {
    h4 {
      color: $offblack;

      i,
      svg {
        color: $reidblue;
      }
    }

    ul {
      list-style: none;
      padding: 0px;
      margin: 0px;

      i,
      svg {
        color: $green;
      }
    }
  }
}

#fancybox-outer,
#fancybox-wrap {
  @include radius();

  .product-related-products {
    background-color: $cream;
  }
}

#fancybox-content {
  border-width: 0px !important;

  h3 {
    color: darken($cream, 50%);
  }

  .product-field-display {
    h5 {
      @include text-sm;
    }

    img {
      max-width: 100px;
      height: auto;
    }
  }
}

.popupwrapper {
  width: 500px;
  @media screen and (max-width: 830px) {
    width: 100% !important;
  }
}

.popupheader {
  background-color: $white;
  color: $offblack;
  @include text-md;
}

/*Basket styles*/

/*Form Wizard$green*/
.bs-wizard {
  border-bottom: solid 1px #e0e0e0;
  padding: 0 0 10px 0;
}

.bs-wizard > .bs-wizard-step {
  padding: 0;
  position: relative;
}

.bs-wizard > .bs-wizard-step + .bs-wizard-step {
}

.bs-wizard > .bs-wizard-step .bs-wizard-stepnum {
  color: #595959;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

.bs-wizard > .bs-wizard-step .bs-wizard-info {
  color: #999;
  font-size: 14px;
}

.bs-wizard > .bs-wizard-step > .bs-wizard-dot {
  position: absolute;
  width: 30px;
  height: 30px;
  display: block;
  background: lighten($green, 30%);
  top: 45px;
  left: 50%;
  margin-top: -15px;
  margin-left: -15px;
  border-radius: 50%;
}

.bs-wizard > .bs-wizard-step > .bs-wizard-dot:after {
  content: " ";
  width: 14px;
  height: 14px;
  background: $green;
  border-radius: 50px;
  position: absolute;
  top: 8px;
  left: 8px;
}

.bs-wizard > .bs-wizard-step > .progress {
  position: relative;
  border-radius: 0px;
  height: 8px;
  box-shadow: none;
  margin: 20px 0;
}

.bs-wizard > .bs-wizard-step > .progress > .progress-bar {
  width: 0px;
  box-shadow: none;
  background: lighten($green, 30%);
}

.bs-wizard > .bs-wizard-step.complete > .progress > .progress-bar {
  width: 100%;
}

.bs-wizard > .bs-wizard-step.active > .progress > .progress-bar {
  width: 50%;
}

.bs-wizard > .bs-wizard-step:first-child.active > .progress > .progress-bar {
  width: 0%;
}

.bs-wizard > .bs-wizard-step:last-child.active > .progress > .progress-bar {
  width: 100%;
}

.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-dot {
  background-color: #f5f5f5;
}

.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-dot:after {
  opacity: 0;
}

.bs-wizard > .bs-wizard-step:first-child > .progress {
  left: 50%;
  width: 50%;
}

.bs-wizard > .bs-wizard-step:last-child > .progress {
  width: 50%;
}

.bs-wizard > .bs-wizard-step.disabled a.bs-wizard-dot {
  pointer-events: none;
}

/*END Form Wizard*/

/*Basket Nav*/

#first_name_input,
#shipto_first_name_input {
  .form-control {
    width: 49% !important;
    display: inline !important;
  }
}

.greywrap {
  background-color: lighten($cream, 5%);
  /*background-color: #e5d9cb;*/
}

.container.checkout {
  width: 1200px;

  @media (max-width: $screen-xs-max) {
    width: 100%;
  }

  .countdownwrap {
    margin-top: 0px !important;
  }

  .countdownwrap #countdown {
    font-size: 14px;
  }

  .countdownwrap .icon i {
    font-size: 24px;
  }
}

.container.orders,
.container.user,
.container.article {
  width: 1200px;

  @media (max-width: $screen-xs-max) {
    width: 100%;
  }
}

/*Forms*/

.form-control,
.form-control-lg {
  background-color: lighten($cream, 7%);
  border: $reidgrey 2px solid;
  // @include radius(0px);
}

.col-form-label {
  &.required {
    label:after {
      //font-family: FontAwesome;
      display: inline-block;
      content: "\*";
      color: #d3494c;

      font-variant-position: super !important;
      margin-left: 5px;
    }
  }

  font-size: 0.875rem;
}

.form-group.bank_account_nr,
.form-group.po_compulsary,
.form-group.admin_cust_note,
#admin_cust_note_div,
#admin_cust_note_input,
.form-group.marked_for_social,
#marked_for_social_div,
#marked_for_social_input {
  display: none !important;
}

/*Forms*/

input.formcontrol[type="checkbox"] {
  width: auto !important;
}

.freeshipping {
  i,
  svg {
    color: $green !important;
  }
}

/*Radios*/

.text-center {
  text-align: center;
}

.thick-end {
  border-bottom: 10px solid black;
}

.thin-end {
  border-bottom: 1px solid black;
}

.formContainer {
  .formRequired {
    color: red;
  }

  fieldset:nth-child(n + 2) {
    border: $reidblue 2px solid;
    //@include radius();
    padding: 20px;

    .form-control,
    .control-label {
      font-size: 80%;
    }

    label {
      vertical-align: middle !important;
    }

    legend {
      font-size: 120%;
      color: $brown;
      display: inherit;
      width: auto;
      padding: 0px 10px;
    }
  }
}

.g-recaptcha {
  margin: 15px auto !important;
  width: auto !important;
  height: auto !important;
  text-align: -webkit-center;
  text-align: -moz-center;
  text-align: -o-center;
  text-align: -ms-center;
}

div.icons {
  display: none;
}

div.blog {
}

.item-page,
div.blog {
  h1 {
  }
}

.jobapp {
  fieldset {
    padding: 10px !important;

    .form-group {
      margin-bottom: 2px;

      label {
        margin-bottom: 0px;
      }

      .form-control {
        padding: 5px;
        height: auto;
      }
    }

    button {
      width: 100%;
    }
  }
}

.form-control-file {
  font-size: 80%;
}

.zendesk-button:focus {
  outline: none;
  position: absolute;
  bottom: 0px;
  right: 0px;
}
.zendesk-button {
  outline: none;
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.myaccount-wrap {
  .card {
    p {
      height: 70px;
      @media screen and (max-width: 830px) {
        height: auto !important;
      }
    }
    a {
      color: $reidblue !important;
      font-weight: 600;
    }
  }
}
