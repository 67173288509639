// @import "../node_modules/bootstrap/scss/../node_modules/bootstrap/scss/..//node_modules/bootstrap/scss/bootstrap.scss";

// Custom.scss
// Option B: Include parts of Bootstrap

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)

// scss-docs-start import-stack
// Configuration

// @import "../node_modules/bootstrap/scss/functions";
@import "_2overrides.scss";

 @import "../node_modules/bootstrap/scss/bootstrap.scss";
// @import "../node_modules/bootstrap/scss/functions";
// @import "../node_modules/bootstrap/scss/variables";
// @import "../node_modules/bootstrap/scss/mixins/breakpoints";

 @import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css");
// scss-docs-end import-stack