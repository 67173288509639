.btn {

  }

  .btn-success {
    color: $white !important;
    background-color: $success;

    &:hover {
      background-color: darken($success, 10%);
    }

    i,
    svg {
      color: $white !important;
    }
  }
  .btn-xs {
    font-size: 90%;
    padding: 2px 5px;
  }

  .btn-xl {
    font-size: 32px;
  }

  .btn-xxl {
    font-size: 52px;
  }
  .btn-outline-orange {
    color: $white !important;
    background-color: $reidblue;
    border-color: $reidblue;
  }

  .btn-orange {
    color: $white !important;
    background-color: $reidblue;
    border-color: $reidblue;
  }

  .btn {
    .subtext {
      @include text-sm;
    }
  }
  .btn-stripe {
    color: $white;
    border: 2px $reidblue solid;
    &:hover {
      background: transparent;
      color: $reidblue;
    }
  }
