.leftcol {
    z-index: 0;
  h3 {
  }
  .sticky-offset {
    top: 150px;
  }

  @mixin borderenclose {
    border-bottom: 1px solid lighten($reidtext, 60%);
  }

  ul {
    list-style: none;
    padding: 0px;

    li {
      list-style: none;
      padding: 0px;
      &.level-0 {
        display: none;
        opacity: 0.2;
      }
      &.level-0.active {
        display: block;
        opacity: 1;
      }

      // &:before {
      //    font-family: 'bootstrap-icons';
      //     content: "\F285";
      //     color: $reidblue;
      //     margin-right: 5px;
      // }

      &.active {
        a {
          font-weight: 700 !important ;
        }

        &.level-0 {
          > a {
            @include borderenclose;
            &:after {
              font-family: "bootstrap-icons";
              content: "\F282";
              color: $reidblue;
              margin-left: 5px;
              position: absolute;
              right: 10px;
            }
          }
        }
      }

      a {
        padding: 2px 10px;
        color: $reidblue2;
        font-size: 1.2rem;
        font-weight: 500;
        text-transform: uppercase;
        display: block;
        position: relative;
        &:hover {
          background-color: rgba($reidtext, 0.1);
        }
      }

      ul.level-1 {

        margin-top: 3px;
        margin-bottom: 10px;
        @include borderenclose;

        li {
          // &:before {

          //      content: "";
          //      margin-right: 0px;
          //      line-height: normal;
          //  }
          a {
            text-transform: unset;
            font-size: 1rem !important;
            color: $reidtext;
            font-weight: 500 !important;
            padding-left: 30px;
          }
        }
      }
      ul.level-2 {
        padding-left: 10px;
        margin-top: 3px;
        margin-bottom: 10px;
        // @include borderenclose;

        li {
          // &:before {

          //      content: "";
          //      margin-right: 0px;
          //      line-height: normal;
          //  }
          a {
            text-transform: unset;
            font-size: 0.9rem !important;
            color: $reidblue2;
            font-weight: 500 !important;
          }
        }
      }
    }
  }
}
