
.footer {

    padding-left: 0px;
    padding-right: 0px;
    position: relative;
    font-size: 14px;
    color: $white;
    background-color: $reidtext;
    padding-top: 0px;
    padding-bottom: 10px;

    .border-light { border-color: rgba($white,0.1) !important;}

    ul {
        padding: 0px;
        list-style: none;
    }

    @media screen and (max-width: 830px) {
        font-size: 130%;
        text-align: center !important
    }

    .social {
        i {
            font-size: 150%;
        }
    }

    /*ul li a:before {content: "\f054"; font-family: 'bootstrap-icons'; margin-right:4px; color: $reidblue; padding-top:3px; vertical-align: text-top !important; font-size:10px; }*/

    .elementwrap {
        position: relative;
        border-top: $cream 1px solid;
        float: left;
        width: 100%;
        padding-top: 10px;
        margin-top: 10px;
        padding-bottom: 10px
    }

    .elementwrap:before {
        position: absolute;
        top: 0px;
        left: 0px;
        content: "";
        border-top: darken($cream, 10%) 1px solid;
        float: left;
        width: 100%;
    }

    h3,
    h4 {
        padding: 0px;
        margin-bottom: 10px;
        margin-top: 0px
    }

    h3 {
        color: $white;
        font-weight: 400;
        font-size: 150%;
    }

    h4 {
        font-size: 120%;
        color: $white;
        margin-bottom: 2px;

        a {
            color: $white;
        }
    }

    p {
        font-size: 90%;


    }


    .bean:after {
        @include radius(100%);
        width: 50px;
        height: 50px
    }

    a {
        color: lighten($offblack, 60%);
    }

    small {
        font-size: 11px;
        color: lighten($cream, 20%)
    }

}

.disclaimer {
    background-color: #353c40;
    color: #5d656a;
    font-size: 11px;
    padding-top: 15px;
    padding-bottom: 15px;

    a {
        color: #8b969c
    }
}