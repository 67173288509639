



@media screen and (min-width: 320px) {
    html {
        font-size: calc(18px + 6 * ((100vw - 320px) / 680));
    }
}

@media screen and (min-width: 1000px) {
    html {
        font-size: 16px;

    }
}

h1,h2{font-weight: 500; letter-spacing: -0.02rem;}

h1 {

    color: $reidblue2; font-size: 2rem;
}

h2 {
    color: $reidblue2; font-size: 1.5rem;
}

h3{
    color: $reidblue2; font-size: 1.2rem; font-weight: 300; letter-spacing: -0.01rem;
}

